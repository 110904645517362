<script setup>
import {onMounted, inject, reactive} from 'vue'
import { useTranslation } from '../../composables/useTranslation'
const { translationsReady, trans } = useTranslation()

const props = defineProps(['showAll', 'trainers'])
const axios = inject('axios')

const state = reactive({
    trainers: []
})

const fetchTrainers = async () => {
    const response = await axios.get('/api/blocks/trainers', {
        params: {
            showAll: props.showAll,
            trainers: JSON.parse(props.trainers)
        }
    })

    state.trainers = response.data.trainers
}

onMounted(fetchTrainers)
</script>

<template>
    <section class="team" v-if="state.trainers.length && translationsReady">
        <div class="container">
            <div class="row">
                <template v-for="trainer in state.trainers" :key="trainer.id">
                    <div class="col-lg-12 column" v-if="trainer.settings.active">
                        <article v-if="trainer.settings.active" class="item">
                            <div class="item__image" v-if="trainer.settings?.image">
                                <picture>
                                    <img :src="'/storage/'+trainer.settings?.image"
                                              :alt="trainer.name">
                                </picture>
                            </div>
                            <div class="item__content">
                                <h4 v-html="trainer.settings?.title"></h4>
                                <h5>{{ trainer.settings?.blockquote }}</h5>
                                <div v-html="trainer.settings?.perex"></div>
<!--                                <a :href="trainer.team_url" class="c-button is-link">-->
<!--                                    {{ trans('globalne.Zobraziť detail')}}-->
<!--                                    <span class="arrow">-->
<!--                                        <img src="/public/img/icons/arrow-right.svg"-->
<!--                                             alt=""-->
<!--                                        >-->
<!--                                    </span>-->
<!--                                </a>-->
                            </div>
                        </article>
                    </div>
                </template>
            </div>
        </div>
    </section>
</template>
