<script setup>
import {inject, reactive} from 'vue'
import { toast } from 'vue3-toastify'
import {useTranslation} from '../../composables/useTranslation'

const {translationsReady, trans} = useTranslation()

const props = defineProps([
    'formRoute'
])

const axios = inject('axios')

const state = reactive({
    old_password: '',
    new_password: '',
    new_password_confirmation: '',
})

const handleSubmit = async (e) => {
    e.preventDefault()

    try {
        const response = await axios.post(props.formRoute, {
            old_password: state.old_password,
            new_password: state.new_password,
            new_password_confirmation: state.new_password_confirmation,
        })

        if (response.status === 200) {
            toast.success(response.data.message)
            state.old_password = ''
            state.new_password = ''
            state.new_password_confirmation = ''
        }
    } catch (error) {
        toast.error(error.response.data.message)
    }
}
</script>

<template>
    <form v-if="translationsReady" @submit.prevent="handleSubmit">
        <div class="input-wrap">
            <label for="old_password">{{ trans('formular.Staré heslo label') }}</label>
            <input type="password"
                   name="old_password"
                   id="old_password"
                   :placeholder="trans('formular.Staré heslo placeholder')"
                   required
                   v-model="state.old_password"
            >
        </div>
        <div class="input-wrap">
            <label for="new_password">{{ trans('formular.Nové heslo label') }}</label>
            <input type="password"
                   name="new_password"
                   id="new_password"
                   :placeholder="trans('formular.Nové heslo placeholder')"
                   required
                   v-model="state.new_password"
            >
        </div>
        <div class="input-wrap">
            <label for="new_password_confirmation">{{ trans('formular.Nové heslo znova label') }}</label>
            <input type="password"
                   name="new_password_confirmation"
                   id="new_password_confirmation"
                   :placeholder="trans('formular.Nové heslo znova placeholder')"
                   required
                   v-model="state.new_password_confirmation"
            >
        </div>

        <div class="form-submit">
            <button type="submit" class="c-button is-default is-normal-size">
                {{ trans('formular.Chcem aktualizovať svoje heslo') }}
            </button>
        </div>
    </form>
</template>
