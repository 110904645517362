<script setup>
import { inject, onMounted, ref, computed } from 'vue'
import { useTranslation } from '../composables/useTranslation.js'

const axios = inject('axios')

const { trans, translationsReady } = useTranslation()

const props = defineProps(['byMemberId', 'teamMemberId', 'byExerciseId', 'exerciseId'])

const programs = ref([])

const fetchPrograms = async () => {
    try {
        const response = await axios.get('/api/blocks/programs-site', {
            params: {
                byMemberId: props.byMemberId,
                teamMemberId: props.teamMemberId,
                byExerciseId: props.byExerciseId,
                exerciseId: props.exerciseId
            }
        })
        programs.value = response.data.programs
    } catch (error) {
        console.error(error)
    }
}

onMounted(fetchPrograms())
</script>

<template>
    <div class="profile__content programs" v-if="programs.length && translationsReady">
        <div class="row">
            <div class="col-xl-3 col-lg-4 col-md-6 column" v-for="program in programs" :key="program.id">
                <a :href="program.site_url" class="item">
                    <picture>
                        <img :src="/storage/+program.settings.image" :alt="program.name">
                    </picture>
                    <div class="content">
                        <h4>{{ program.name }}</h4>
                        <p>
                            {{ program.settings?.perex && program.settings?.perex.length > 120 ? (program.settings?.perex.substring(0, 120) + '...') : program.settings?.perex }}
                        </p>
                    </div>
                </a>
            </div>
        </div>
    </div>

<!--    <section class="products archive" v-if="programs.length && translationsReady">-->
<!--        <div class="container">-->
<!--            <div class="row">-->
<!--                <div class="col-lg-6 column" v-for="program in programs" :key="program.id">-->
<!--                    <div class="c-product is-light is-on-archive">-->
<!--                        <div class="c-product__body">-->
<!--                            <div class="c-product__image" :style="`background-image: url(/storage/${program.settings.carousel_image})`"></div>-->
<!--                            <div class="c-product__content-wrap">-->
<!--                                <div class="c-product__content">-->
<!--                                    <h3>{{ localizedValue(program.name) }}</h3>-->
<!--                                    <div v-html="program.settings?.description"></div>-->
<!--                                    <a :href="program.site_url" class="c-button is-default is-normal-size">-->
<!--                                        {{ trans('ostatne.Viac informácií') }}-->
<!--                                    </a>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <template v-if="program.settings?.short_description">-->
<!--                            <div class="c-product__footer" v-html="program.settings?.short_description"></div>-->
<!--                        </template>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </section>-->
</template>
