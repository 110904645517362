<script setup>
import { inject, reactive } from 'vue'
import { toast } from 'vue3-toastify'

import FileUploader from './Fields/FileUploader.vue'
import {useTranslation} from '../../composables/useTranslation'

const {translationsReady, trans} = useTranslation()

const axios = inject('axios')
const emitter = inject('emitter')

const props = defineProps([
    'formRoute',
    'imageUrl'
])

const state = reactive({
    form: {
        photo: null
    }
})

const setPhoto = (file) => state.form.photo = file

const handleSubmit = async (e) => {
    e.preventDefault()

    const formData = new FormData()
    formData.append('photo', state.form.photo)

    try {
        const response = await axios.post(props.formRoute, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })

        if ( response.status === 200 ) {
            emitter.emit('updateUserPhoto', response.data.photoUrl)
            toast.success(response.data.message)
        }
    } catch (error) {
        console.error(error)
    }
}
</script>

<template>
    <form v-if="translationsReady" @submit.prevent="handleSubmit">
        <FileUploader @on-file-change="setPhoto($event)" :user-image="imageUrl" />

        <div class="form-submit">
            <button type="submit" class="c-button is-default is-normal-size">
                {{ trans('formular.Aktualizovať profilovú fotku') }}
            </button>
        </div>
    </form>
</template>
