<script setup>
import {reactive, inject, onMounted} from 'vue'
import {Splide, SplideSlide, SplideTrack} from '@splidejs/vue-splide'

const axios = inject('axios')

defineProps(['classes'])

const state = reactive({
    carouselOptions: {
        type: 'loop',
        perPage: 1,
        focus: 'center',
        arrows: true,
        autoplay: true,
        fixedWidth: '380px',
        gap: '18px',
        pagination: false,
        breakpoints: {
            991: {
                perPage: 4,
            },
            767: {
                perPage: 3,
            },
            575: {
                perPage: 2,
                fixedWidth: '300px'
            },
            400: {
                perPage: 1,
            },
        }
    },
    programs: [],
})

const fetchPrograms = async () => {

    try {
        const response = await axios.get('/api/blocks/programs')
        state.programs = response.data.programs
    } catch (error) {
        console.error(error)
    }
}

onMounted(fetchPrograms)
</script>

<template>
    <section v-if="state.programs.length" :class="classes" class="base-carousel">
        <Splide :options="state.carouselOptions" :has-track="false">
            <div class="inner">
                <SplideTrack>
                    <SplideSlide v-for="program in state.programs" :key="program.id">
                        <a :href="program.site_url">
                            <picture>
                                <img :src="'/storage/' + program.settings.image" :alt="program.name">
                            </picture>
                        </a>
                    </SplideSlide>
                </SplideTrack>

                <div class="splide__arrows">
                    <button class="splide__arrow splide__arrow--prev">
                        <img src="/public/img/arrow-left.png" alt="">
                    </button>
                    <button class="splide__arrow splide__arrow--next">
                        <img src="/public/img/arrow-right.png" alt="">
                    </button>
                </div>
            </div>
        </Splide>
    </section>
</template>
