<script setup>
import {inject, reactive} from 'vue'
import {toast} from 'vue3-toastify'
import {useTranslation} from '../../composables/useTranslation'

const {translationsReady, trans} = useTranslation()
const props = defineProps(['user', 'formRoute'])
const axios = inject('axios')
let localUser = reactive({...props.user})
const state = reactive({
    billing_street: localUser.profile.billing_street ?? '',
    billing_city: localUser.profile.billing_city ?? '',
    billing_zip_code: localUser.profile.billing_zip_code ?? '',
    billing_country: localUser.profile.billing_country ?? '',
})

const handleSubmit = async (e) => {
    e.preventDefault()

    try {
        const response = await axios.post(props.formRoute, {
            billing_street: state.billing_street,
            billing_city: state.billing_city,
            billing_zip_code: state.billing_zip_code,
            billing_country: state.billing_country,
        })

        if (response.status === 200) {
            toast.success(response.data.message)
        }
    } catch (error) {
        toast.error(error.response.data.message)
    }
}
</script>

<template>
    <form v-if="translationsReady" @submit.prevent="handleSubmit">
        <div class="input-wrap">
            <label for="billing_street">{{ trans('formular.Adresa label') }}</label>
            <input type="text" name="billing_street" id="billing_street"
                   :placeholder="trans('formular.Adresa placeholder')"
                   v-model="state.billing_street">
        </div>
        <div class="input-wrap">
            <label for="billing_city">{{ trans('formular.Mesto label') }}</label>
            <input type="text" name="billing_city" id="billing_city"
                   :placeholder="trans('formular.Mesto placeholder')"
                   v-model="state.billing_city">
        </div>
        <div class="input-wrap">
            <label for="billing_zip_code">{{ trans('formular.PSČ label') }}</label>
            <input type="text" name="billing_zip_code" id="billing_zip_code"
                   :placeholder="trans('formular.PSČ placeholder')"
                   v-model="state.billing_zip_code">
        </div>
        <div class="input-wrap">
            <label for="billing_country">{{ trans('formular.Krajina label') }}</label>
            <input type="text" name="billing_country" id="billing_country"
                   :placeholder="trans('formular.Krajina placeholder')"
                   v-model="state.billing_country">
        </div>

        <div class="form-submit">
            <button type="submit" class="c-button is-default is-normal-size">
                {{ trans('formular.Aktualizovať fakturačné údaje') }}
            </button>
        </div>
    </form>
</template>
