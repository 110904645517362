<script setup>
import {onMounted, inject, reactive} from 'vue'
import {useTranslation} from '../../composables/useTranslation'
import {useApp} from '../../composables/useApp.js'

const {translationsReady, trans} = useTranslation()

const props = defineProps(['filters', 'query'])
const emitter = inject('emitter')
const form = reactive({open: false})

const {localizedValue} = useApp()

onMounted(() => {
    emitter.on('toggle-filtration', () => {
        form.open = !form.open // Remove .value from here
    })

    for (let key of Object.keys(props.filters)) {
        if (key === 'dates') {
            form[key] = Object.entries(props.filters[key]).map(([id, name]) => {
                return {name, value: props.query[name]};
            });
        } else {
            form[key] = Object.entries(props.filters[key]).map(([id, item]) => {
                return {id: item.id, name: item.name, selected: props.query[key] && !!props.query[key].includes(item.id)};
            });
        }

    }
});
const submitForm = () => {
    let selectedItems = []
    // prevent page reload and update URL with form data
    for (let key of Object.keys(form)) {
        if (key === 'dates') {
            if (typeof form[key][0].value !== 'undefined') {
                selectedItems[form[key][0].name] = [form[key][0].value];
            }
            if (typeof form[key][1].value !== 'undefined') {
                selectedItems[form[key][1].name] = [form[key][1].value];
            }

        } else {
            if (key !== 'open') {
                selectedItems[key] = form[key].filter(item => item.selected).map(item => item.id);
            }
        }
    }

    let queryParamsObject = Object.keys(selectedItems).reduce((obj, key) => {
        if (selectedItems[key].length > 0) {
            obj[key] = selectedItems[key].join(',');
        }
        return obj;
    }, {});


    let queryParams = new URLSearchParams(queryParamsObject).toString()
    window.history.pushState(null, '', '?' + queryParams)
    window.location.reload();
}
</script>
<template>
    <div class="filtration" v-if="form.open && translationsReady">
        <div class="container">
            <div class="filtration-top">
                <h4>{{ trans('globalne.Filtrácia') }}</h4>
                <button class="close" type="button" @click="form.open = false">
                    {{ trans('globalne.Zatvoriť') }}
                    <span class="trigger">
                        <img src="/public/img/close-filtration.svg" alt="">
                    </span>
                </button>
            </div>

            <form @submit.prevent="submitForm">
                <div class="filtration-row">
                    <div class="filtration-column">
                        <h5 class="mt-sm-2">{{ trans('globalne.date') }}</h5>
                        <div v-for="(item) in form['dates']" class="input-wrap">
                            <label :for="item.name">{{ trans('globalne.'+item.name) }}</label>
                            <input type="date" :name="item.name" v-model="item.value">
                        </div>
                        <h5 class="mt-sm-2">{{ trans('globalne.difficulty') }}</h5>
                        <div v-for="(item) in form['difficulty']" class="c-checkbox c-checkbox--filter d-inline-block">
                            <label>
                                <input type="checkbox" name="difficulty" :value="item.id" v-model="item.selected">
                                <span class="label" v-html="item.name"></span>
                            </label>
                        </div>
                    </div>

                    <div class="filtration-column">
                        <h5>{{ trans('globalne.exercise_category_id') }}</h5>
                        <div v-for="(item) in form['exercise_category_id']"
                             class="c-checkbox c-checkbox--filter d-inline-block">
                            <label>
                                <input type="checkbox" :name="item.name" :value="item.id" v-model="item.selected">
                                <span class="label" v-html="item.name"></span>
                            </label>
                        </div>
                        <h5 class="mt-lg-5">{{ trans('globalne.exercise_part_id') }}</h5>
                        <div v-for="(item) in form['exercise_part_id']"
                             class="c-checkbox c-checkbox--filter d-inline-block">
                            <label>
                                <input type="checkbox" :name="item.name" :value="item.id" v-model="item.selected">
                                <span class="label" v-html="item.name"></span>
                            </label>
                        </div>
                        <h5 class="mt-lg-5">{{ trans('globalne.exercise_duration_id') }}</h5>
                        <div v-for="(item) in form['exercise_duration_id']"
                             class="c-checkbox c-checkbox--filter d-inline-block">
                            <label>
                                <input type="checkbox" :name="item.name" :value="item.id" v-model="item.selected">
                                <span class="label" v-html="item.name"></span>
                            </label>
                        </div>
                    </div>

                    <div class="filtration-column wide-sm">
                        <h5 class="mt-sm-2">{{ trans('globalne.coach_id') }}</h5>
                        <div v-for="(item) in form['coach_id']" class="c-checkbox c-checkbox--filter d-inline-block">
                            <label>
                                <input type="checkbox" :name="item.name" :value="item.id" v-model="item.selected">
                                <span class="label" v-html="item.name"></span>
                            </label>
                        </div>
                    </div>
                    <div class="filtration-column wide">
                        <h5 class="mt-sm-2">{{ trans('globalne.exercise_type_id') }}</h5>
                        <div v-for="(item) in form['exercise_type_id']"
                             class="c-checkbox c-checkbox--filter d-inline-block">
                            <label>
                                <input type="checkbox" :name="item.name" :value="item.id" v-model="item.selected">
                                <span class="label" v-html="item.name"></span>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="filtration-cta">
                    <button type="submit" class="c-button is-secondary is-normal-size">
                        {{ trans('globalne.Vyfiltrovať') }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>
