<script setup>
import { onMounted, onUpdated, inject, reactive, ref } from 'vue'
import { useApp } from '../../composables/useApp.js'
import { useTranslation } from '../../composables/useTranslation.js'

const { localizedValue } = useApp()

const { trans, translationsReady } = useTranslation()

const axios = inject('axios')
const emitter = inject('emitter')
const paginationWrapper = ref(null)

const state = reactive({
    exercises: [],
    page: 1,
    pagination: null
})

const addPaginationEvents = () => {
    paginationWrapper.value.querySelectorAll('li').forEach((item) => {
        item.addEventListener('click', (event) => {
            event.preventDefault()
            state.page = item.getAttribute('data-page')
            loadExercises()
        })
    })
}

const loadExercises = async () => {
    emitter.emit('loader', true)

    try {
        const response = await axios.get('/api/blocks/exercises', {
            params: {
                page: state.page,
            },
        })

        state.exercises = response.data.exercises
        state.pagination = response.data.pagination
        console.log(state.exercises.data)
    } catch (error) {
        console.error(error)
    }

    emitter.emit('loader', false)
}

onMounted(() => {
    loadExercises()
})

onUpdated(() => {
    addPaginationEvents()
})
</script>

<template>

    <!--    <section v-if="state.exercises.data?.length" class="exercises">-->
    <!--        <div class="container">-->
    <!--            <div class="row">-->
    <!--                <template v-for="exercise in state.exercises.data" :key="exercise.id">-->
    <!--                    <div class="col-lg-6 mb-4">-->
    <!--                        <article class="exercises__item">-->
    <!--                            <div class="exercises__item-header">-->
    <!--                                <h4>{{ localizedValue(exercise.name) }}</h4>-->
    <!--                            </div>-->
    <!--                            <div class="exercises__item-body" v-html="exercise.body"></div>-->
    <!--    <a :href="exercise.exercise_url" class="c-button is-link">-->
    <!--        {{ trans('globalne.Zobraziť detail')}}-->
    <!--        <span class="arrow">-->
    <!--                                    <img src="/public/img/icons/arrow-right.svg"-->
    <!--                                         alt=""-->
    <!--                                    >-->
    <!--                                </span>-->
    <!--    </a>-->
    <!--                        </article>-->
    <!--                    </div>-->
    <!--                </template>-->
    <!--            </div>-->

    <!--            <div v-html="state.pagination" ref="paginationWrapper"></div>-->
    <!--        </div>-->
    <!--    </section>-->

    <section class="team" v-if="state.exercises.data?.length">
        <div class="container">
            <div class="row">
                <template v-for="exercise in state.exercises.data" :key="exercise.id">
                    <div class="col-lg-6 column">

                        <article class="item">
                            <div class="item__image" v-if="!exercise.settings.video_iframe">
                                <picture>
                                    <img :src="'/storage'+exercise.settings.image" :alt="exercise.name">
                                </picture>
                            </div>
                            <div class="item__content">
                                <div class="iframe-holder" v-if="exercise.settings.video_iframe"
                                     v-html="exercise.settings.video_iframe">
                                </div>
                                <h4>{{ exercise.name }}</h4>
                                <div v-html="exercise.settings.perex"></div>
                                <a :href="exercise.site_url" class="c-button is-link is-purple">
                                    {{ trans('globalne.Zobraziť detail')}}
                                    <span class="arrow">
                                        <img src="/public/img/icons/arrow-right-white.svg"
                                             alt="icon arrow"
                                        >
                                    </span>
                                </a>
                            </div>

                        </article>
                    </div>
                </template>
            </div>
            <div v-html="state.pagination" ref="paginationWrapper"></div>
        </div>
    </section>
</template>
