import './bootstrap'

import.meta.glob([
    '../img/**',
])

import { createApp } from 'vue'
import { createVfm, ModalsContainer } from 'vue-final-modal'
import Vue3Toastify, { toast } from 'vue3-toastify'

// Styles
import 'vue-final-modal/style.css'
import 'vue3-toastify/dist/index.css'

// Plugins
import { VueMasonryPlugin } from 'vue-masonry'

import axios from 'axios'

import mitt from 'mitt'
const emitter = mitt()

// Components
import MasonryGrid from './components/MasonryGrid.vue'
import ImageCarousel from './components/ImageCarousel.vue'
import ModalCore from './components/ModalCore.vue'
import AccordionSection from './components/AccordionSection.vue'
import ProgramDetailCarousel from './components/ProgramDetailCarousel.vue'
import ProgressCircle from './components/ProgressCircle.vue'
import RegistrationForm from './components/Forms/RegistrationForm.vue'
import ChangePasswordForm from './components/Forms/ChangePasswordForm.vue'
import ChangeProfilePhotoForm from './components/Forms/ChangeProfilePhotoForm.vue'
import UpdateProfileForm from "./components/Forms/UpdateProfileForm.vue";
import FilterButton from './components/Profile/FilterButton.vue'
import TrainingItem from './components/Profile/TrainingItem.vue'
import ItemFiltration from './components/Profile/ItemFiltration.vue'
import BaseCarousel from './components/BaseCarousel.vue'
import UserAvatar from './components/Header/UserAvatar.vue'
import WindowLoader from './components/WindowLoader.vue'

import FlashMessage from './components/FlashMessage.vue'
// Blocks
import TeamBlock from './components/Blocks/TeamBlock.vue'
import GalleryBlock from './components/Blocks/GalleryBlock.vue'
import ExerciseListing from './components/Blocks/ExerciseListing.vue'
import BlogBlock from "./components/Blocks/BlogBlock.vue";
import ProgramListing from './components/ProgramListing.vue'
import ProgramDetail from './components/ProgramDetail.vue'
import ArticleCategoryListing from './components/ArticleCategoryListing.vue'

//Partial
import PageHeaderProductPrice from "./components/Partial/PageHeaderProductPrice.vue";

// window.global ||= window;

window.showModal = function (name, data) {
    emitter.emit('showModal', { name: name, data: data })
}

window.flash = function(message, type) {
    emitter.emit('flash', { message: message, type: type })
}

setTimeout( function() {
    console.log('window', window)
}, 5000)

const vfm = createVfm()

const app = createApp({
    components: {
        MasonryGrid,
        ImageCarousel,
        ModalCore,
        ModalsContainer,
        AccordionSection,
        ProgramDetailCarousel,
        ProgressCircle,
        RegistrationForm,
        ChangePasswordForm,
        UpdateProfileForm,
        ChangeProfilePhotoForm,
        FilterButton,
        TrainingItem,
        ItemFiltration,
        BaseCarousel,
        UserAvatar,
        TeamBlock,
        ExerciseListing,
        BlogBlock,
        GalleryBlock,
        WindowLoader,
        FlashMessage,
        ProgramListing,
        ProgramDetail,
        ArticleCategoryListing,
        PageHeaderProductPrice
    }
})

const locale = document.querySelector('body').getAttribute('data-locale')
app.config.globalProperties.locale = locale
localStorage.setItem('locale', locale)

app.config.globalProperties.localizedValue = (value) => {
    return value[app.config.globalProperties.locale] || value
}

app.use(vfm)
app.use(VueMasonryPlugin)
app.use(Vue3Toastify, {
    theme: 'colored',
    // autoClose: false,
    position: toast.POSITION.BOTTOM_RIGHT,
    dangerouslyHTMLString: true
})
app.provide('emitter', emitter)
app.provide('axios', axios)
// app.provide('locale', document.querySelector('body').getAttribute('data-locale'))
app.mount('#app')
