<script setup>
import { onMounted, computed, inject, reactive } from 'vue'
import VueEasyLightbox, { useEasyLightbox } from 'vue-easy-lightbox'

const props = defineProps(['galleryId', 'bgColor', 'sectionId'])

const axios = inject('axios')

const state = reactive({
    images: [],
    content: '',
})

onMounted( () => fetchGallery())

const fetchGallery = () => {
    axios.get('/api/blocks/gallery-items?gallery_id='+props.galleryId)
        .then( response => {
        if (response.data && response.data.media) {
            state.images = response.data.media
            state.content = response.data.content
        }
    }).catch( error => console.log(error) )
}

const imagesToLightbox = computed(() => {
    return state.images.map(image => {
        console.log(image)
        return {
            src: image.src,
            alt: image.alt
        }
    })
})

const {
    // methods
    show, onHide, changeIndex,
    // refs
    visibleRef, indexRef, imgsRef
} = useEasyLightbox({
    // src / src[]
    imgs: imagesToLightbox,
    // initial index
    initIndex: 0
})

const handleShow = (index) => {
    changeIndex(index)
    show()
}
</script>

<template>
    <section v-if="state.images.length"
             class="c-gallery-block"
             :style="{ backgroundColor: bgColor }"
    >
        <vue-easy-lightbox
            :visible="visibleRef"
            :imgs="imgsRef"
            :index="indexRef"
            @hide="onHide"
        />
        <div class="container">
<!--            <div class="row">-->
<!--                <div class="col-md-12">-->
<!--                    <div class="c-gallery-block__title" v-html="state.content"></div>-->
<!--                </div>-->
<!--            </div>-->
           <div class="row">
               <div class="col-lg-12">
                   <div class="row">
                       <template v-for="(image, index) in state.images" :key="image">
                           <div class="col-lg-3 col-6 column">
                               <div class="c-gallery-block__item">
                                   <picture @click.prevent="handleShow(index)">
                                       <img :src="image.src" :alt="image.alt ?? ''">
                                   </picture>
                               </div>
                           </div>
                       </template>
                   </div>
               </div>
           </div>
        </div>
    </section>
</template>
