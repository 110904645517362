<script setup>
import { onMounted, inject } from 'vue'
import { toast } from 'vue3-toastify'

const props = defineProps([
    'message',
    'type'
])

const emitter = inject('emitter')

onMounted( () => {
    if (props.message) {
        flashMessage(props.message, props.type)
    }

    emitter.on('flash', (data) => {
        flashMessage(data.message, data.type)
    })
})

const flashMessage = (message, type) => {
    if ( type === 'success' ) {
        toast.success(message)
    } else if ( type === 'error' ) {
        toast.error(message)
    } else if ( type === 'info' ) {
        toast.info(message)
    } else {
        toast(message)
    }
}
</script>

<template></template>
