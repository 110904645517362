<script setup>
import {inject, onMounted, defineAsyncComponent} from 'vue'
import { useModal, useModalSlot } from 'vue-final-modal'
import DefaultModal from './Modals/DefaultModal.vue'

const emitter = inject('emitter')
const { open, close, patchOptions } = useModal({
    component: DefaultModal,
    attrs: {
        contentData: {},
        onClose() {
            close()
        },
        onSubmit() {
            //TODO Better submit event handling (don't close on error when submit)
            // close()
        }
    },
    slots: {
        default: useModalSlot({
            component: DefaultModal,
            attrs: {}
        })
    }
})

onMounted(() => emitter.on('showModal', (carrier) => handleModalSettings(carrier)))

const handleModalSettings = (carrier) => {
    patchOptions({
        slots: {
            default: useModalSlot({
                // import component based on name
                component: defineAsyncComponent(() => import(`./Modals/${carrier.name}.vue`)),
                attrs: {
                    contentData: carrier.data ? JSON.parse(carrier.data) : null,
                    // Bind ModalContent events
                    onClose() {
                        close()
                    }
                },
            }),
        },
    })

    open()
}
</script>
<template></template>
