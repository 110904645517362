import { ref } from 'vue'
import { useLocalStorage } from './useLocalStorage'
import axios from 'axios'

let translationStrings = ref(null)
let translationsReady = ref(false)

export function useTranslation() {

    const { setWithExpiry, getWithExpiry } = useLocalStorage()

    const setTranslations = () => {
        translationStrings.value = getWithExpiry('translations')

        if ( !translationStrings.value || document.querySelector('body').getAttribute('data-locale-changed') ) {

            axios.post('/api/translations', {
                    currentLocale: document.querySelector('body').getAttribute('data-locale')
                })
                .then( response => {
                    // console.log('translations',response.data.translations)
                    translationStrings.value = response.data.translations
                    setWithExpiry('translations', response.data.translations, 60 * 60 * 2)
                    translationsReady.value = true
                }).catch( error => console.error(error))
        } else {
            translationsReady.value = true
        }
    }

    setTranslations()

    const trans = (keys, replace = {}) => {
        keys = keys.split('.')

        let translations = translationStrings.value

        keys.forEach(function(keyTmp) {
            translations = translations[keyTmp]
                ? translations[keyTmp]
                : keyTmp

        })

        Object.keys(replace).forEach(function (key) {
            translations = translations.replace(':' + key, replace[key])
        })

        return translations
    }

    return {
        setTranslations,
        translationsReady,
        trans
    }
}
