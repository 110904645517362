<script setup>
import { reactive, onMounted } from 'vue'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/vue-splide'

const props = defineProps(['items'])

const state = reactive({
    carouselOptions: {
        arrows: false,
    }
})

onMounted( () => {
    console.log(props.items)
})
</script>

<template>
    <div class="program-detail__carousel" v-if="items && items.length">
        <Splide :options="state.carouselOptions" :has-track="false">
            <div class="program-detail__carousel__inner">
                <SplideTrack>
                    <SplideSlide v-for="(item, key) in items" :key="key">
                        <div class="program-detail__carousel__slide c-box">
                            <div v-html="item.content"></div>
                        </div>
                    </SplideSlide>
                </SplideTrack>
            </div>
        </Splide>
    </div>
</template>
