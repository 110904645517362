<script setup>
import {reactive} from 'vue'
import SlideUpDown from 'vue-slide-up-down'

defineProps(['data'])

const state = reactive({
    active: false
})

const toggle = () => state.active = !state.active
</script>

<template>
    <li class="c-accordion__list-item">
        <div class="c-accordion__list-item__title"
             :class="{ 'active': state.active }"
             @click="toggle">
            <h4>{{ data.title }}</h4>
            <button type="button">
                <img src="/public/img/chevron-down.svg" alt="">
            </button>
        </div>

        <slide-up-down :active="state.active">
            <div class="c-accordion__list-item__content" v-if="state.active" v-html="data.content"></div>
        </slide-up-down>
    </li>
</template>
