<script setup>
import {reactive} from 'vue'
import {VueFinalModal} from 'vue-final-modal'

const props = defineProps([
    'contentTransition', 'contentData'
])

const state = reactive({
    contentTransition: props.contentTransition ?? 'vfm-fade',
})
</script>

<template>
    <VueFinalModal class="c-modal" content-class="c-modal__wrapper" overlay-transition="vfm-fade"
                   :contentData="contentData" :content-transition="state.contentTransition">
        <button @click="$emit('close')" class="close"><img src="/public/img/close.svg" alt=""></button>
        <div class="c-modal__content"><slot :contentData="contentData"/></div>
    </VueFinalModal>
</template>
