<script setup>
import {inject, onMounted, ref} from 'vue'

const axios = inject('axios')

const props = defineProps(['productId'])
const product = ref(null)

onMounted(async () => {
    await callProduct()
})

async function callProduct(){
    const {data} = await axios.get(`/api/blocks/product?product_id=${props.productId}`)
    product.value = data.product
}
</script>

<template>

    <div class="price is-rounded">
        <span class="price-value">{{ product.price.replace('.', ',') }}€</span>
        <span class="price-addon">/{{ product.item_data?.interval_text }}</span>
    </div>

</template>
