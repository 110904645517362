<script setup>
import { reactive } from 'vue'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/vue-splide'

const props = defineProps(['data'])

const state = reactive({
    carouselOptions: {
        type: 'loop',
        perPage: 5,
        focus: 'center',
        arrows: true,
        autoplay: true,
        pagination: false,
        breakpoints: {
            1199: {perPage: 3},
            991: {perPage: 3},
            767: {perPage: 2},
            575: {perPage: 2, arrows: false},
            400: {perPage: 1}
        }
    }
})
</script>

<template>
    <section class="c-image-carousel">
        <div class="container" v-html="data.title"></div>

        <Splide :options="state.carouselOptions" :has-track="false">
            <div class="c-image-carousel__inner">
                <div class="container">
                    <SplideTrack>
                        <SplideSlide v-for="(discount,key) in data.discounts" :key="key">
                            <div class="c-image-carousel__slide">
                                <a v-if="discount.link && discount.link != '#'" :href="discount.link" target="_blank">
                                    <img :src="`/storage/${discount.image}`" :alt="discount.alt">
                                </a>
                                <img v-else :src="`/storage/${discount.image}`" :alt="discount.alt">
                            </div>
                            <div class="c-image-carousel__slide-content" v-if="discount.title">
                                <p class="title">{{ discount.title }}</p>
                                <div v-html="discount.text"></div>
                            </div>
                        </SplideSlide>
                    </SplideTrack>
                </div>

                <div class="splide__arrows">
                    <button class="splide__arrow splide__arrow--prev">
                        <img src="/public/img/arr-left.svg" alt="">
                    </button>
                    <button class="splide__arrow splide__arrow--next">
                        <img src="/public/img/arr-right.svg" alt="">
                    </button>
                </div>
            </div>
        </Splide>
    </section>
</template>
