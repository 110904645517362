<script setup>
import {inject, onMounted, ref} from 'vue'
import {useTranslation} from '../composables/useTranslation.js'
import {prefixUrl} from "../helpers.js";

const axios = inject('axios')
const {trans, translationsReady} = useTranslation()

const props = defineProps(['item', 'site', 'activeMembership'])

const exercises = ref(null)
const trainers = ref(null)

onMounted(async () => {
    await callExercises()
    await callTrainers()
})

async function callTrainers(){
    const {data} = await axios.get(`/api/blocks/trainers`, {
        params: {
            ids: props.site.settings.trainer_id
        }
    })
    trainers.value = data.trainers
}

async function callExercises(){
    const {data} = await axios.get(`/api/blocks/exercises`, {
        params: {
            ids: props.site.settings.exercises_id
        }
    })
    exercises.value = data.exercises
}

</script>

<template>

    <section class="program-detail custom">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="top-back">
                        <a :href="trans('programy.button spat url')" class="">
                            <span class="c-button is-white-full">
                                <img src="/public/img/arrow-left.png" alt="icon-left">
                            </span>
                            {{ trans('programy.button spat text') }}
                        </a>
                    </div>
                </div>

                <div class="col-md-7">
                    <div class="content">
                        <h1>{{ site.name }}</h1>
                        <div v-html="item.text"></div>
                    </div>
                    <div class="bottom" v-if="activeMembership">
                        <a :href="prefixUrl()+site.program_slug" class="c-button is-default is-normal-size">
                            {{ trans('programy.button cvicenia text') }}
                        </a>
                    </div>
                    <div class="content-bottom" v-else>
                        <span>{{ trans('programy.cennik text') }}</span>
                        <a :href="trans('programy.button cennik url')" class="c-button is-primary is-normal-size">
                            {{ trans('programy.button cennik text') }}
                        </a>
                    </div>
                </div>

                <div class="col-md-5">
                    <div class="c-video-player without-image" v-if="item.video_url">
                        <div class="video">
                            <iframe width="100%" height="315" :src="item.video_url"
                                    title="" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row items-exercise" v-if="exercises && exercises.length">
                <div class="col-md-12">
                    <h2>{{ trans('programy.cvicenia nadpis') }}</h2>
                </div>
                <div class="col-md-12">
                    <div class="item-exercise" v-for="exercise in exercises">
                        <div class="row">
                            <div class="col-md-12">
                                <h3>{{exercise.name }}</h3>
                            </div>
                            <div class="col-md-7" v-html="exercise.settings.perex_program">

                            </div>
                            <div class="col-md-5" v-html="exercise.settings.perex_program_list">

                            </div>
                            <div class="col-md-12" v-if="exercise.settings.video_preview_url">
                                <button class="c-button is-primary is-normal-size js-modal-trigger" data-modal-name="VideoModal" :data-modal-props="JSON.stringify({video_url: exercise.settings.video_preview_url})">
                                    {{ exercise.settings.video_preview_text }}
                                    <img src="/public/img/icon-play.svg" alt="icon-play"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row items-trainer" v-if="trainers && trainers.length">
                <div class="col-md-12">
                    <h2>{{ trans('programy.treneri nadpis') }}</h2>
                </div>
                <div class="col-md-12">
                    <div class="item-exercise item-trainer" v-for="trainer in trainers">
                        <div class="row">
                            <div class="col-md-3 col-sm-4">
                                <img :src="'/storage/'+trainer.settings?.image" :alt="trainer.name">
                                <div class="blockquote" v-if="trainer.settings?.blockquote">
                                    <blockquote>{{ trainer.settings?.blockquote }}</blockquote>
                                </div>
                            </div>
                            <div class="col-md-9 col-sm-8">
                                <h4 v-html="trainer.settings?.title"></h4>
                                <p class="tag" v-if="trainer.settings?.tag">{{ trainer.settings?.tag }}</p>
                                <div v-html="trainer.settings?.perex"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>

</template>
