<script setup>
import {onMounted, ref, reactive, inject, computed} from 'vue'
import {toast} from 'vue3-toastify'
import {useTranslation} from '../../composables/useTranslation'
import FormError from './FormError.vue'
import {prefixUrl} from "../../helpers.js";

const {translationsReady, trans} = useTranslation()
const axios = inject('axios')
const props = defineProps({
    formRoute: {
        type: String,
        default: prefixUrl()+'/register',
    },
    edit: {
        default: true,
    },
    user: {
        type: Object,
        default: null,
    },
})

const selectedMembershipFreeTrial = computed(() => {
    if (!state.selectedMembership) {
        return false;
    }

    const selectedMembership = Array.isArray(memberships.value)
        ? memberships.value.find((m) => m.id === state.selectedMembership) : undefined;
    const hasFreeTrial = selectedMembership ? selectedMembership.free_trial : false;

    if (hasFreeTrial) {
        state.contribution = '';
    }
    return hasFreeTrial;
});

const urlParams = new URLSearchParams(window.location.search)
const isSubmitting = ref(false);
const errors = reactive({})
let localUser = reactive({...props.user})
let discountCard = reactive({});
let isValidCoupon = ref(false)

const state = reactive({
    years: new Date().getFullYear() - 1900,
    name: localUser?.name,
    surname: localUser?.surname,
    nickname: localUser?.profile?.nickname ?? '',
    email: localUser?.email ?? '',
    email_confirmation: localUser?.email ?? '',
    password: '',
    password_confirmation: '',
    phone: localUser?.phone ?? '',
    gender: localUser?.profile?.gender ?? '',
    height: localUser?.profile?.height ?? 170,
    weight: localUser?.profile?.weight ?? 55,
    activity: localUser?.profile?.activity ?? '',
    training_place: localUser?.profile?.training_place ?? '',
    benefit_plus: localUser?.profile?.benefit_plus ?? '',
    birthdate: localUser?.profile?.birthdate ?? '',
    general_terms_and_conditions: localUser?.profile?.general_terms_and_conditions ?? '',
    personal_data_agreement: localUser?.profile?.personal_data_agreement ?? '',
    coupon: urlParams.has('coupon') ? urlParams.get('coupon') : '',
    item_id: urlParams.has('item_id') ? urlParams.get('item_id') : '',
    discount_card_id: '',
    discount_card_value: '',
    selectedMembership: '',
    contribution: '',
})

const memberships = reactive({value: ''})

onMounted(checkDiscountCardUrl)

async function fetchMemberships(disableCoupon = false, retry = true) {
    let query = ''

    // use the new addToQuery function
    query = addToQuery(query, 'coupon', state.coupon)
    query = addToQuery(query, 'item_id', state.item_id)
    if (Object.keys(discountCard).length) {
        query = addToQuery(query, 'discount_card', discountCard.id)
    }

    if (disableCoupon === true) {
        query = ''
    }

    query = addToQuery(query, 'currentLocale', localStorage.getItem('locale'))

    try {
        const response = await axios.get('/api/memberships?' + query)
        memberships.value = response.data
        isValidCoupon = query.includes('coupon')
        if (isValidCoupon && translationsReady) {
            toast.success(trans('profil.kupon bol aplikovany'));
        }
        state.selectedMembership = null
    } catch (error) {
        errors.value = {coupon: {text: error.response.data}};
        for (let key in errors.value) {
            toast.error(errors.value[key].text);
        }

        //refresh memberships without coupon
        if (retry) {
            await fetchMemberships(true, false)
        }
    }
}

function addToQuery(query, key, value) {
    if (value.length || value) {
        query += (query.length ? '&' : '') + key + '=' + value
    }
    return query
}

//check if user is registering on url with discount card event
function checkDiscountCardUrl() {
    axios.get('/api/check-discount-url?url=' + window.location.pathname.substring(1)).then((response) => {
        for (let key in response.data.discount_card) {
            discountCard[key] = response.data.discount_card[key];
        }
        fetchMemberships()
    })
}

// Define submitForm function to handle form submission
const submitForm = async () => {
    isSubmitting.value = true;
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    try {
        const response = await axios.post(props.formRoute, {
            name: state.name,
            surname: state.surname,
            email: state.email,
            email_confirmation: state.email_confirmation,
            password: state.password,
            password_confirmation: state.password_confirmation,
            phone: state.phone,
            gender: state.gender,
            height: state.height,
            weight: state.weight,
            activity: state.activity,
            training_place: state.training_place,
            birthdate: state.birthdate,
            coupon: state.coupon,
            membership: state.selectedMembership,
            contribution: state.contribution,
            benefit_plus: state.benefit_plus,
            general_terms_and_conditions: state.general_terms_and_conditions,
            personal_data_agreement: state.personal_data_agreement,
            discount_card_value: state.discount_card_value,
            discount_card_id: discountCard.id
        })

        if (response.status === 200 && response.request.responseURL && !props.edit) {
            window.location.href = response.request.responseURL
        } else {
            toast.success(response.data.message)
            isSubmitting.value = false
        }

    } catch (error) {
        isSubmitting.value = false;
        if (error.response && error.response.status === 422) {
            toast.error(error.response.data.message)
            errors.value = error.response.data.errors
        } else {
            toast.error(error)
            console.error(`Error submitting form: ${error}`)
        }
    }
}
</script>

<template>
    <form v-if="translationsReady" @submit.prevent="submitForm" method="post" class="register">
        <div class="input-wrap">
            <label for="firstname">{{ trans('formular.Meno label') }}*</label>
            <input v-model="state.name" type="text" id="firstname" name="name" minlength="2" required>
            <template v-if="errors.value?.name">
                <FormError v-for="(error, key) in errors.value.name" :error="error" :key="key"/>
            </template>
        </div>
        <div class="input-wrap">
            <label for="surname">{{ trans('formular.Priezvisko label') }}*</label>
            <input v-model="state.surname" type="text" id="surname" name="surname" minlength="2" required>
            <template v-if="errors.value?.surname">
                <FormError v-for="(error, key) in errors.value.surname" :error="error" :key="key"/>
            </template>
        </div>
        <template v-if="edit">
            <div class="input-wrap">
                <label for="email">{{ trans('formular.Email label') }}</label>
                <input v-model="state.email" type="email" id="email" name="email" readonly>
                <template v-if="errors.value?.email">
                    <template v-if="errors.value?.email">
                        <FormError v-for="(error, key) in errors.value.email" :error="error" :key="key"/>
                    </template>
                </template>
            </div>
        </template>
        <template v-else>
            <div class="input-wrap">
                <label for="email">{{ trans('formular.Email label') }}*</label>
                <input v-model="state.email" type="email" id="email" name="email" placeholder="@" required>
                <template v-if="errors.value?.email">
                    <FormError v-for="(error, key) in errors.value.email" :error="error" :key="key"/>
                </template>
            </div>
        </template>
        <template v-if="!edit">
            <div class="input-wrap">
                <label for="email_confirmation">{{ trans('formular.Email zopakovať label') }}*</label>
                <input v-model="state.email_confirmation" type="email" id="email_confirmation" name="email_confirmation" minlength="8" placeholder="@" required>
                <template v-if="errors.value?.email_confirmation">
                    <FormError v-for="(error, key) in errors.value.email_confirmation" :error="error" :key="key"/>
                </template>
            </div>
            <div class="input-wrap">
                <label for="password">{{ trans('formular.Heslo label') }}*</label>
                <input v-model="state.password" type="password" name="password" id="password" placeholder="******" minlength="8" required>
                <template v-if="errors.value?.password">
                    <FormError v-for="(error, key) in errors.value.password" :error="error" :key="key"/>
                </template>
            </div>
            <div class="input-wrap">
                <label for="password_confirmation">{{ trans('formular.Zopakovať heslo label') }}*</label>
                <input v-model="state.password_confirmation" type="password" name="password_confirmation" id="password_confirmation" placeholder="******" required>
                <template v-if="errors.value?.password_confirmation">
                    <FormError v-for="(error, key) in errors.value.password_confirmation" :error="error" :key="key"/>
                </template>
            </div>
        </template>
        <div class="input-wrap">
            <label for="phone">{{ trans('formular.Telefónne číslo label') }}</label>
            <input v-model="state.phone" type="tel" id="phone" name="phone">
            <template v-if="errors.value?.phone">
                <FormError v-for="(error, key) in errors.value.phone" :error="error" :key="key"/>
            </template>
        </div>
        <div class="input-wrap">
            <label for="training_place">{{ trans('formular.Mesto label') }}</label>
            <input v-model="state.training_place" type="text" id="training_place" name="training_place">
            <template v-if="errors.value?.training_place">
                <FormError v-for="(error, key) in errors.value.training_place" :error="error" :key="key"/>
            </template>
        </div>
        <div class="input-wrap">
            <label for="birthday">{{ trans('formular.Dátum narodenia label') }}*</label>
            <input type="date" name="birthday" id="birthday" v-model="state.birthdate" required>
            <template v-if="errors.value?.birthdate">
                <FormError v-for="(error, key) in errors.value.birthdate" :error="error" :key="key"/>
            </template>
        </div>
        <div class="input-wrap">
            <label for="gender">{{ trans('formular.Pohlavie label') }}*</label>
            <select v-model="state.gender" name="gender" id="gender" required>
                <option>Pohlavie</option>
                <option value="1">{{ trans('formular.Žena') }}</option>
                <option value="2">{{ trans('formular.Muž') }}</option>
            </select>
            <template v-if="errors.value?.gender">
                <FormError v-for="(error, key) in errors.value.gender" :error="error" :key="key"/>
            </template>
        </div>
        <!--        <div class="input-wrap">-->
        <!--            <label for="weight">Vek</label>-->
        <!--            <select v-model="state.year" name="year" id="year" required>-->
        <!--                <option disabled selected>Vek</option>-->
        <!--                <option :value="new Date().getFullYear()">{{ new Date().getFullYear() }}</option>-->
        <!--                <option v-for="year in state.years" :key="year" :value="new Date().getFullYear() - year">-->
        <!--                    {{ new Date().getFullYear() - year }}-->
        <!--                </option>-->
        <!--            </select>-->
        <!--        </div>-->
        <div class="input-group">
            <div class="input-wrap">
                <label for="height">{{ trans('formular.Výška label') }}*</label>
                <select v-model="state.height" name="height" id="height" required>
                    <option disabled selected>{{ trans('formular.Výška label') }}</option>
                    <option v-for="height in 121" :key="height" :value="height + 119">{{ height + 119 }} cm</option>
                </select>
                <template v-if="errors.value?.height">
                    <FormError v-for="(error, key) in errors.value.height" :error="error" :key="key"/>
                </template>
            </div>
            <div class="input-wrap">
                <label for="weight">{{ trans('formular.Váha label') }}*</label>
                <select v-model="state.weight" name="weight" id="weight" required>
                    <option disabled selected>Váha</option>
                    <option v-for="weight in 151" :key="weight" :value="weight + 29">{{ weight + 29 }} kg</option>
                </select>
                <template v-if="errors.value?.weight">
                    <FormError v-for="(error, key) in errors.value.weight" :error="error" :key="key"/>
                </template>
            </div>
        </div>
        <div class="input-wrap">
            <label for="activity">{{ trans('formular.Pohyb label') }}</label>
            <select v-model="state.activity" name="activity" id="activity">
                <option>{{ trans('formular.Koľko dní do týždňa máš pohyb?') }}</option>
                <option value="0">0</option>
                <option v-for="activity in 7" :key="activity" :value="activity">{{ activity }}</option>
            </select>
            <template v-if="errors.value?.activity">
                <FormError v-for="(error, key) in errors.value.activity" :error="error" :key="key"/>
            </template>
        </div>
        <div class="helper">{{ trans('formular.Polia su povinne') }}</div>

        <template v-if="!edit">
            <div class="programs">
                <h3>{{ trans('formular.Prosím, vyber si') }}</h3>
                <div v-for="(membership,key) in memberships.value" :key="key" class="c-radio c-radio--register">
                    <label>
                        <input v-model="state.selectedMembership" type="radio" name="membership" :value="membership.id" required>
                        <span class="check"></span>
                        <span class="content">
                            <span class="content-text">
                                <span class="content-title">{{ membership.name }}</span>
                                    <span v-if="membership.item_data?.subtitle" class="content-description">{{ membership.item_data.subtitle }}</span>
                                    <span v-else-if="membership.free_trial" class="content-description" v-html="membership.item_data.free_description"></span>
                            </span>
                            <span class="content-image" :style="{ backgroundImage: `url(/storage/${membership.image})` }">
                                <span class="price">
                                    <span class="price-value">{{ membership.customer_price_format }}</span>
                                    <span v-if="membership.item_data.interval_text" class="price-addon uppercase">/{{ membership.item_data.interval_text }}</span>
                                </span>
                            </span>
                        </span>
                    </label>
                </div>
                <template v-if="errors.value?.selectedMembership">
                    <FormError v-for="(error, key) in errors.value.selectedMembership" :error="error" :key="key"/>
                </template>
            </div>
            <template v-if="Object.keys(discountCard).length">
                <h5>{{ discountCard.name }}</h5>
                <div class="input-wrap">
                    <input v-model="state.discount_card_value" type="text" name="coupon" id="coupon" required :placeholder="trans('formular.zadaj cislo placeholder')+discountCard.name">
                    <template v-if="errors.value?.discount_card_value">
                        <FormError v-for="(error, key) in errors.value.discount_card_value" :error="error" :key="key"/>
                    </template>
                </div>
            </template>
            <template v-else>
                <h5>{{ trans('formular.Zľavový kód label') }}</h5>
                <div class="input-wrap d-flex">
                    <input v-model="state.coupon" type="text" name="coupon" id="coupon" :placeholder="trans('formular.Zľavový kód label')">
                    <button class="c-button is-primary is-normal-size" style="margin-left: 1rem;" type="button" v-on:click="fetchMemberships">{{ trans('formular.Použiť zľavový kód tlačidlo') }}</button>
                </div>
                <template v-if="errors.value?.coupon">
                    <FormError v-for="(error, key) in errors.value.coupon" :error="error" :key="key"/>
                </template>
            </template>
            <div v-if="!state.coupon.length && !Object.keys(discountCard).length" class="c-checkbox">
                <label>
                    <input v-model="state.benefit_plus" type="checkbox" name="benefit_plus">
                    <span class="check"><img src="/public/img/check-white.svg" alt=""></span>
                    <span class="label">{{ trans('formular.Uplatňujem si poukážku BENEFIT PLUS label') }}</span>
                </label>
                <template v-if="errors.value?.benefit_plus">
                    <FormError v-for="(error, key) in errors.value.benefit_plus" :error="error" :key="key"/>
                </template>
            </div>
            <div v-if="!state.coupon.length && !selectedMembershipFreeTrial" class="c-checkbox">
                <label>
                    <input v-model="state.contribution" type="checkbox" name="contribution">
                    <span class="check"><img src="/public/img/check-white.svg" alt=""></span>
                    <span class="label" v-html="trans('formular.Príspevok 1 € MY SME LIVE label')"></span>
                </label>
                <template v-if="errors.value?.contribution">
                    <FormError v-for="(error, key) in errors.value.contribution" :error="error" :key="key"/>
                </template>
            </div>
            <div class="c-checkbox">
                <label>
                    <input v-model="state.general_terms_and_conditions" type="checkbox" name="general_terms_and_conditions" required>
                    <span class="check"><img src="/public/img/check-white.svg" alt=""></span>
                    <span class="label" v-html="trans('formular.Súhlasím so všeobecnými obchodnými podmienkami label')"></span>
                </label>
                <template v-if="errors.value?.general_terms_and_conditions">
                    <FormError v-for="(error, key) in errors.value.general_terms_and_conditions" :error="error" :key="key"/>
                </template>
            </div>
            <div class="c-checkbox">
                <label>
                    <input v-model="state.personal_data_agreement" type="checkbox" name="personal_data_agreement" required>
                    <span class="check"><img src="/public/img/check-white.svg" alt=""></span>
                    <span class="label" v-html="trans('formular.Súhlasím so spracovaním osobných údajov label')"></span>
                </label>
                <template v-if="errors.value?.personal_data_agreement">
                    <FormError v-for="(error, key) in errors.value.personal_data_agreement" :error="error" :key="key"/>
                </template>
            </div>
        </template>

        <div class="form-submit">
            <button type="submit" class="c-button is-primary is-normal-size" :disabled="isSubmitting">
                <template v-if="isSubmitting">{{ trans('formular.Spracovávam') }}</template>
                <template v-else-if="!edit">{{ trans('formular.Pokračovať na výber platby') }}</template>
                <template v-else>{{ trans('formular.Uložiť zmeny') }}</template>
            </button>
        </div>
    </form>
</template>
