<script setup>
import { ref, onMounted, inject } from 'vue'

const emitter = inject('emitter')
const showLoader = ref(false)

onMounted( () => {
    emitter.on('loader', (value) => {
        showLoader.value = value
    })
})
</script>

<template>
    <div v-if="showLoader" class="window-loader">
        <span class="loader"></span>
    </div>
</template>