<script setup>
import {useTranslation} from '../../composables/useTranslation'
import {prefixUrl} from "../../helpers.js";

const {translationsReady, trans} = useTranslation()
const props = defineProps(['article'])

const emitToFavorites = () => {
    axios.post('/profil/emit-favorite', {id: props.article.id})
        .then(function (response) {
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        });
}

</script>

<template>
    <a v-if="translationsReady" :href="prefixUrl()+`/profil/archive/detail/${article.id}`" class="archive__item">
        <picture>
            <img v-if="article.image" :src="article.image.original_url" :alt="article.id" class="cover">
            <img v-else src="https://placehold.co/300x300" :alt="article.id" class="cover">
        </picture>
        <div class="archive__item__meta-top">
            <p v-if="article.exercise_duration" class="time">
                <img src="/public/img/clock.svg" alt="">
                {{ article.exercise_duration.value }} min.
            </p>
            <time datetime="2021-01-21">
                {{ article.human_date }}
            </time>
        </div>
        <div class="archive__item__actions">
            <button @click.prevent="emitToFavorites" type="button" class="c-action-button like">
                <img src="/public/img/cta-heart-white.svg" alt="">
            </button>
            <button type="button" class="c-action-button check">
                <img src="/public/img/cta-check-white.svg" alt="">
            </button>
            <button type="button" class="c-action-button edit">
                <img src="/public/img/cta-pencil-white.svg" alt="">
            </button>
        </div>
        <div class="archive__item__bottom">
            <figure v-if="article.exercise_part">
                <img :src="`/storage/${article.exercise_part.image}`" alt="">
            </figure>
            <figure v-else-if="article.exercise_category">
                <img :src="`/storage/${article.exercise_category.image}`" alt="">
            </figure>
            <div class="text">
                <h4>{{ article.title }}</h4>
                <div v-if="article.difficulty" class="difficult">
                    <p>{{ trans('profil.Narocnost') }}</p>
                    <div class="difficulties">
                        <span v-if="article.difficulty === 1" v-for="d in article.difficulty" :key="d"
                              style="background-color: #ADFF00;"></span>
                        <span v-if="article.difficulty === 2" v-for="d in article.difficulty" :key="d"
                              style="background-color: #b7ff00;"></span>
                        <span v-if="article.difficulty === 3" v-for="d in article.difficulty" :key="d"
                              style="background-color: #ffb300;"></span>
                        <span v-if="article.difficulty === 4" v-for="d in article.difficulty" :key="d"
                              style="background-color: #ff6a00;"></span>
                        <span v-if="article.difficulty === 5" v-for="d in article.difficulty" :key="d"
                              style="background-color: #FF2E00;"></span>
                        <span v-for="d in (5 - article.difficulty)" :key="d" style="background-color: #E1E2E3;"></span>
                    </div>
                </div>
            </div>
        </div>
    </a>
</template>
