<script setup>
import {onMounted, inject, reactive} from 'vue'
import {useTranslation} from '../../composables/useTranslation'
import {prefixUrl, setElHeight} from "../../helpers.js";

const {translationsReady, trans} = useTranslation()

const axios = inject('axios')
const emitter = inject('emitter')
const props = defineProps(['blogUrl', 'arrowIcon'])

const state = reactive({
    articles: [],
})

const loadArticles = async () => {
    emitter.emit('loader', true)

    try {
        const response = await axios.get('/api/blocks/articles')

        state.articles = response.data.articles
        setTimeout(() => {
            let postsSection = document.getElementById('js-posts-section');
            setElHeight(postsSection.querySelectorAll('.c-post p'))
        }, 300);

    } catch (error) {
        console.error(error)
    }

    emitter.emit('loader', false)
}

onMounted(() => {
    loadArticles()
})
</script>

<template>
    <section v-if="translationsReady" class="posts" id="js-posts-section">
        <div class="container">
            <div class="top-bar">
                <h2>{{ trans('blog.Novinky') }}</h2>
                <a :href="blogUrl" class="c-button is-primary is-normal-size">
                    {{ trans('blog.Všetky články') }}
                    <svg width="15px" height="14px">
                        <use href="../../../../public/img/icons.svg#btnArrow"></use>
                    </svg>
                </a>
            </div>

            <div class="row">
                <div v-for="article in state.articles" class="col-lg-4 col-md-4 column">
                    <a :href="prefixUrl()+'/'+article.slug" class="c-post">
                        <article>
                            <figure>
                                <picture><img :src="article.image.original_url" :alt="article.title"></picture>
                            </figure>
                            <div class="c-post__inner">
                                <span class="arrow"><img src="../../../../public/img/post-arrow.svg" alt="arrow"></span>
                                <p>{{ article.title }}</p>
                                <time :datetime="article.date">{{ article.human_date }}</time>
                            </div>
                        </article>
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>

</style>
