<script setup>
import { ref, reactive, onMounted } from 'vue'
import { useTranslation } from '../../../composables/useTranslation'

const { translationsReady, trans } = useTranslation()

const props = defineProps(['userImage'])
const emits = defineEmits(['onFileChange'])

const fileInput = ref(null)
// const fileText = ref(null)

const state = reactive({
    photoUrl: props.userImage ?? null
})

const handleClick = () => {
    console.log('click')
    fileInput.value.click()
}

const handleFile = (e) => {
    state.photoUrl = null
    const file = e.target.files[0]

    state.photoUrl = URL.createObjectURL(file)
    emits('onFileChange', file)
}
</script>

<template>
    <div v-if="translationsReady" class="c-file-uploader">
        <input type="file" ref="fileInput" @change="handleFile" />
        <div class="c-file-uploader__inner" @click="handleClick">
            <template v-if="state.photoUrl">
                <img :src="state.photoUrl" alt="" class="preview">
            </template>
            <template v-else>
                <img src="/public/img/upload.svg" alt="">
                <p v-html="trans('formular.Presuňte sem súbory alebo vyberte súbor')"></p>
            </template>
        </div>
    </div>
</template>
