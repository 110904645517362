<script setup>
import {inject, onMounted, ref, computed} from 'vue'
import {useTranslation} from '../composables/useTranslation.js'
import {useApp} from '../composables/useApp.js'

const props = defineProps([
    'itemId'
])

const axios = inject('axios')

const {trans, translationsReady} = useTranslation()
const {localizedValue} = useApp()

const articles = ref([])

const fetchArticles = async () => {
    try {
        const response = await axios.get('/api/blocks/article-category-listing?category_id='+props.itemId)
        articles.value = response.data.articles
    } catch (error) {
        console.error(error)
    }
}

onMounted(fetchArticles)
</script>

<template>

    <section class="posts" id="js-posts-section" v-if="articles.length && translationsReady">
        <div class="container">
            <div class="row">

                <div class="col-lg-4 col-sm-6 column" v-for="article in articles" :key="article.id">
                    <a :href="article.site_url" class="c-post">
                        <article>
                            <figure>
                                <picture>
                                    <img :src="article.image_url" :alt="article.name">
                                </picture>
                            </figure>
                            <div class="c-post__inner">
                                    <span class="arrow">
                                        <img src="/public/img/post-arrow.svg" alt="icon arrow">
                                    </span>
                                <p>{{ article.title }}</p>
                                <time :datetime="article.published_at_formatted">
                                    {{ article.published_at_formatted }}
                                </time>
                            </div>
                        </article>
                    </a>
                </div>

            </div>
        </div>
    </section>
</template>
