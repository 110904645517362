<script setup>
import { onMounted, inject, ref } from 'vue'
import UserPic from '../../../img/user.png'

const props = defineProps(['avatar', 'route'])
const avatarUrl = ref(props.avatar)
const emitter = inject('emitter')

onMounted(() => {
    emitter.on('updateUserPhoto', (photoUrl) => {
        avatarUrl.value = photoUrl
    })
})
</script>

<template>
    <a v-if="avatarUrl" :href="route" class="profile">
        <img :src="avatarUrl" alt="">
    </a>
    <a v-else :href="route" class="c-button is-primary is-small-size profile-icon">
        <img :src="UserPic" alt="user">
    </a>
</template>
