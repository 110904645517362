<script setup>
import {inject} from 'vue'
import {useTranslation} from '../../composables/useTranslation'

const {translationsReady, trans} = useTranslation()
const props = defineProps(['query'])
const emitter = inject('emitter')

let countObj = 0;

for (let key in props.query) {
    if (key !== 'page' && props.query[key]) {
        countObj += props.query[key].split(',').length;
    }
}

const handleClick = () => {
    emitter.emit('toggle-filtration')
}
</script>

<template>
    <button v-if="translationsReady" type="button" class="filter-button" @click="handleClick">
        <img src="/public/img/filter.svg" alt="">{{ trans('globalne.Filtrácia') }}
        <span class="count">{{ countObj }}</span>
    </button>
</template>
